import { HitsList } from "./hits-list/hits-list";
import styles from "./hits.module.scss";
import { FC } from "react";
import { Timer } from "./timer/timer";

export const Hits: FC<any> = ({ hits }) => {
  return (
    hits?.items?.length > 0 && (
      <section className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>Хиты недели</h2>
          <div className={styles.timer}>
            <p className={styles.timerText}>До конца действия акции осталось:</p>
            <Timer activeTo={hits.element.active_to} />
          </div>
        </div>
        <HitsList items={hits.items} />
      </section>
    )
  );
};
