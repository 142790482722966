import { CloseIcon, SoundIcon, SoundMutedIcon } from "@/components/icon";
import styles from "./mobile-action.module.scss";
import { FC } from "react";

interface IMobileAction {
  handleMuted: () => void;
  isMuted: boolean;
  onClose: () => void;
  isActiveStory: boolean;
};

export const MobileAction: FC<IMobileAction> = (props) => {
  const {
    handleMuted,
    isMuted,
    onClose,
    isActiveStory
  } = props;
  return (
    <div className={`${styles.mobile_actions} ${!isActiveStory && styles.not_active}`}>
      <button
        className={`${styles.button} ${styles.sound}`}
        onClick={handleMuted}
      >
        {isMuted ? <SoundMutedIcon /> : <SoundIcon />}
      </button>
      <button
        onClick={onClose}
        className={`${styles.button} ${styles.close}`}
      >
        <CloseIcon />
      </button>
    </div>
  );
};