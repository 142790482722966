import Link from "next/link";
import styles from "./hits-item.module.scss";
import { FC, useEffect, useState } from "react";
import Image from "next/image";
import NoPhotoImg from "../../../../../assets/images/no-photo.webp";
import { Stars } from "@/components/stars/stars";
import ButtonComponent from "@/components/button/button";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import {
  addToCart,
  addToFavourites,
  getIsNewUser,
  getUserInfo,
} from "@/services/redux/features/userSlice";
import { useRouter } from "next/navigation";
import { FavouriteIcon } from "@/components/icon";
import classNames from "classnames";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { AuthPopup } from "@/components/popups";
import { IHitsItem } from "@/services/types/types";
import {
  clearItemsInfo,
  getCustomerInfo,
  setCustomerInfo,
} from "@/services/redux/features/orderSlice";
import { priceWithSpaces } from "@/utils/functions/price-with-spaces";

export const HitsItem: FC<IHitsItem> = (props) => {
  const { card } = props;
  const isAvailable = card.available;
  const [isClient, setIsClient] = useState(false);
  const router = useRouter();
  const user = useAppSelector(getUserInfo);
  const customer = useAppSelector(getCustomerInfo);
  const dispatch = useAppDispatch();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const isNewUser = useAppSelector(getIsNewUser);
  const currentStep = useAppSelector((state) => state.order.step);

  const countDiscount =
    card.labels && card.labels.discount && card.discount_percent >= 10
      ? `-${card.discount_percent}%`
      : false;

  const isInCart = user.info.cart.find(
    (item: any) => item.product_id === card.id
  );

  const favouriteClassnames = classNames({
    [styles.favouriteIcon]: true,
    // @ts-ignore: Unreachable code error
    [styles.favouriteIconActive]: user.info.favourites.includes(card.id),
  });

  const handleFavouriteClick = () => {
    if (!user.auth.isAuth) {
      handleOpenModal();
    } else {
      dispatch(
        addToFavourites({ id: Number(card.id), price: card.price_discount })
      );
    }
  };

  const handleCartClick = (e: any) => {
    e.preventDefault();
    if (isInCart) {
      router.push("/cart");
    } else {
      const values = {
        name: customer.name ? customer.name : user.info.name,
        last_name: customer.last_name ? customer.last_name : user.info.lastname,
        tel: customer.tel ? customer.tel : user.info.phone,
        email: customer.email ? customer.email : user.info.email,
      };
      if (currentStep >= 2) {
        dispatch(setCustomerInfo({ step: 1, ...values }));
      }
      dispatch(
        addToCart({
          id: card.id,
          quantity: card.diff_pack ? card.diff_pack : 1,
          price: card.price_discount,
          // isChecked: true
        })
      );
      dispatch(clearItemsInfo());
    }
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {showModal && <AuthPopup isOpen={showModal} onClose={handleCloseModal} />}
      {/* {isNewUser && (
        <WelcomeBonusPopup />
      )} */}
      <div className={styles.wrap}>
        <Link prefetch={false} href={`/card/${card.id}`} className={styles.link}>
          <div className={styles.imgWrap}>
            <div className={styles.labelWrap}>
              {countDiscount && <p className={styles.label}>{countDiscount}</p>}
            </div>
            <Image
              src={
                card.preview_picture
                  ? `https://ohotaktiv.ru${card.preview_picture}`
                  : NoPhotoImg
              }
              alt={card.name}
              className={styles.img}
              width={500}
              height={500}
            />
          </div>
        </Link>
        <div className={styles.descriptionWrap}>
          <Link prefetch={false} href={`/card/${card.id}`} className={styles.link}>
            <Stars className={styles.reviews} stars={card.reviews} />
            <div className={styles.priceWrap}>
              <div className={styles.priceContent}>
                <p className={styles.priceOld}>{priceWithSpaces(card.price)}</p>
                <p className={styles.price}>
                  {priceWithSpaces(card.price_discount)}
                </p>
              </div>
              <p className={styles.priceDiscount}>
                {priceWithSpaces(card.price_discount - card.price)}
              </p>
            </div>
            <p className={styles.name}>{card.name}</p>
          </Link>
          <div className={styles.buttonWrap}>
            {isClient ? (
              isInCart ? (
                <ButtonComponent className={styles.inCart}>
                  <>В корзине</>
                </ButtonComponent>
              ) : isAvailable ? (
                <ButtonComponent
                  className={styles.addToCart}
                  onClick={handleCartClick}
                >
                  <>В корзину</>
                </ButtonComponent>
              ) : (
                <ButtonComponent className={styles.isNotAvailable} disabled>
                  <>Отсутствует</>
                </ButtonComponent>
              )
            ) : isAvailable ? (
              <ButtonComponent
                className={styles.addToCart}
                onClick={handleCartClick}
              >
                <>В корзину</>
              </ButtonComponent>
            ) : (
              <ButtonComponent className={styles.isNotAvailable} disabled>
                <>Отсутствует</>
              </ButtonComponent>
            )}
            <button onClick={handleFavouriteClick} className={styles.favourite}>
              <FavouriteIcon className={favouriteClassnames} color="#9ea0a3" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
