import { IStoriesElement } from "@/services/types/types";
import styles from "./range.module.scss";
import { FC } from "react";

interface IRange {
  activeStory: number;
  videoProgress: number;
  durationRef: any;
  stories: Array<IStoriesElement>;
  isLoading: boolean;
  isActive?: boolean;
};

export const Range: FC<IRange> = (props) => {
  const {
    activeStory,
    videoProgress,
    durationRef,
    stories,
    isLoading,
    isActive
  } = props;
  return (
    <div className={styles.ranges}>
      {isActive && stories.map((el, index: number) => (
        <div
          className={`${activeStory === index ? styles.range : styles.not_active}`}
          key={`${index + "range" + "stories"}`}
        >
          {activeStory === index &&
            <>
              <div
                className={styles.range_active}
                style={stories[activeStory].video ?
                  { width: `${videoProgress}%` }
                  :
                  { animationDuration: `${durationRef.current / 1000}s` }
                }
              />
              <div className={styles.range_main} />
            </>
          }
        </div>
      ))}
    </div>
  );
};