"use client";

import { FC } from "react";
import styles from "./brands-popular.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "./swiper.scss";
import Link from "next/link";
import Image from "next/image";
import { IBrandsPopular } from "@/services/types/types";

export interface IBrandItem {
  name: string;
  code: string;
  preview_picture: string;
}

export const BrandsPopular: FC<IBrandsPopular> = ({ brands }) => {
  return (
    <section className={styles.popular}>
      <h2 className={styles.title}>Популярные бренды</h2>
      <Swiper
        className={"swiper-popular-brands"}
        spaceBetween={32}
        allowTouchMove={false}
        slidesPerView={8}
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
          dynamicBullets: true,
          dynamicMainBullets: 2,
        }}
        breakpoints={{
          1439: {
            spaceBetween: 32,
            slidesPerView: 8,
          },
          1365: {
            spaceBetween: 32,
            slidesPerView: 7,
          },
          1023: {
            spaceBetween: 16,
            slidesPerView: 6,
          },
          767: {
            spaceBetween: 16,
            slidesPerView: 5,
            allowTouchMove: true,
          },
          600: {
            spaceBetween: 16,
            slidesPerView: 4,
            allowTouchMove: true,
          },
          320: {
            spaceBetween: 10,
            slidesPerView: 3,
            allowTouchMove: true,
          },
        }}
        navigation
        loop={false}
      >
        {brands.map((item: any, index: number) => {
          return (
            <SwiperSlide key={`${index + "swiperSlide" + "BrandsPopular"}`}>
              <div className={styles.item}>
                <Link href={`/brands/${item.code}`} className={styles.link} prefetch={false}>
                  <Image
                    alt={item.name}
                    src={`https://ohotaktiv.ru${item.preview_picture}`}
                    width={800}
                    height={800}
                    className={styles.img}
                  />
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
