import Link from "next/link";
import styles from "./swiper-link.module.scss";
import { FC, JSX } from "react";

interface ISwiperLink {
  name: string;
  link: string;
  icon: JSX.Element;
}

export const SwiperLink: FC<ISwiperLink> = (props) => {
  const { name, link, icon } = props;
  return (
    <Link prefetch={false} href={link} className={styles.link}>
      <span className={styles.name}>{name}</span>
      {icon}
    </Link>
  );
};