import Image from "next/image";
import styles from "./picture.module.scss";
import { FC } from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";

interface IPicture {
  desktop: string | StaticImport;
  tablet?: string;
  mobile: string;
  alt: string;
  width: number | `${number}`;
  height: number | `${number}`;
  className?: string;
  name?: string;
}

export const Picture: FC<IPicture> = (props) => {
  
  const {
    desktop = "",
    tablet = "",
    mobile = "",
    alt = "",
    width,
    height,
    className = "",
    name = ''
  } = props;
  
  return (
    <picture className={styles.picture}>
      {mobile && <source media="(max-width: 767.98px)" srcSet={mobile}/>}
      {tablet && <source media="(max-width: 1365.98px)" srcSet={tablet}/>}
      <Image
        src={desktop}
        alt={alt}
        width={width}
        height={height}
        className={className}
      />
    </picture>
  );
};
