import Link from "next/link";
import styles from "./category.module.scss";
import {FC} from "react";
import Image from "next/image";
import NoPhotoImg from "../../../../assets/images/no-photo.webp";
import {ICategory} from "@/services/types/types";

type category = {
  category: ICategory;
}

export const Category: FC<category> = (props) => {
  const {category} = props;
  return (
    <Link prefetch={false} className={styles.link} href={`/catalog/${category.code}`}>
      <div className={styles.imgWrap}>
        <Image
          src={
            category.detail_picture !== null
              ? `https://ohotaktiv.ru${category.detail_picture}`
              : NoPhotoImg
          }
          alt={category.name}
          width={800}
          height={800}
          className={styles.img}
        />
      </div>
      {category.name}
    </Link>
  );
};
