import "./categories.scss";
import {FC} from "react";
import {Category} from "./category/category";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination, Grid} from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/grid";
import {ICategory} from "@/services/types/types";

interface ICategories {
  categories: Array<ICategory>;
}

export const Categories: FC<ICategories> = (props) => {
  const {categories} = props;
  return (
    <Swiper
      className={"swiper-categories"}
      modules={[Navigation, Pagination, Grid]}
      spaceBetween={36}
      slidesPerView={6}
      navigation
      pagination={{clickable: true}}
      breakpoints={{
        1439: {
          spaceBetween: 36,
          slidesPerView: 6,
        },
        1023: {
          spaceBetween: 32,
          slidesPerView: 5,
        },
        767: {
          spaceBetween: 32,
          slidesPerView: 4,
        },
        600: {
          spaceBetween: 24,
          slidesPerView: 4,
        },
        320: {
          spaceBetween: 12,
          slidesPerView: 3,
          grid: {
            rows: 2,
            fill: "row",
          },
        },
      }}
    >
      {categories.map((category: ICategory, index: number) => {
        return (
          <SwiperSlide
            key={`${index + "swiperSlide" + "categorieskey"}`}
          >
            <Category category={category}/>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
