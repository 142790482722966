"use client";
import styles from "./stories.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "./swiper.scss";
import { StoriesPopup } from "../popups/stories-popup/stories-popup";
import Image from "next/image";
import { StoriesMobilePopup } from "../popups/stories-mobile-popup/stories-mobile-popup";
import { useWindowSize } from "@/services/hooks/useWindowSize";
import { IAllStories } from "@/services/types/types";
import { getStories } from "@/utils/api/getStories";
import { useAppSelector } from "@/services/redux/hooks";
import { getStoriesViewedList } from "@/services/redux/features/storiesSlice";
import { googleViewStories } from "@/utils/api/google-analytic";

export const Stories: FC= () => {
  
  const storiesRef = useRef(null);
  const [showContentStories, setShowContentStories] = useState(false);
  const [focusedElem, setFocusedElem] = useState(0);
  const [windowWidth] = useWindowSize();
  const [storiesList, setStoriesList] = useState<Array<IAllStories>>([]);
  const storiesChecked = useAppSelector(getStoriesViewedList);
  
  useEffect(() => {
    getStories().then((res) => {
      if (res.success) {
        setStoriesList(res.items);
      }
    });
  }, []);

  const focusStory = (elemIndex: number) => {
    setShowContentStories(true);
    setFocusedElem(elemIndex);
    ym(42989679, "reachGoal", "viewStories");
    googleViewStories();
    document.body.style.overflow = "hidden";
  };
  const close = () => {
    setShowContentStories(false);
    document.body.style.overflow = "auto";
  };

  return (
    <section className={styles.stories_wrap}>
      {showContentStories &&
        (windowWidth > 767 ? (
          <StoriesPopup
            onClose={close}
            focusedElem={focusedElem}
            storiesList={storiesList}
          />
        ) : (
          <StoriesMobilePopup
            onClose={close}
            focusedElem={focusedElem}
            storiesList={storiesList}
          />
        ))}
      <Swiper
        className="swiper-stories--main"
        slidesPerView={"auto"}
        allowTouchMove={true}
        pagination={false}
        spaceBetween={windowWidth < 767 ? 22 : 62}
        ref={storiesRef}
        centerInsufficientSlides={windowWidth < 767 ? false : true}
        loop={false}
        navigation
      >
        {storiesList.map((item, index) => {
          const checked = storiesChecked.find((story) => story === item.id);
          return (
            <SwiperSlide
              key={`${index + "swiper-stories" + index + "swiperSlide"}`}
              className="stories_slide"
            >
              <div
                key={`${index + "swiper-stories" + index + "item"}`}
                className={`${styles.stories_item} ${
                  !!checked ? styles.is_checked : ""
                }`}
                onClick={() => focusStory(index)}
              >
                <div className={styles.image_container}>
                  <Image
                    className={styles.image}
                    alt={item.name}
                    src={`https://ohotaktiv.ru${item.picture}`}
                    width={400}
                    height={400}
                  />
                </div>
                <p className={styles.stories_text}>{item.name}</p>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
