import Link from "next/link";
import styles from "./about.module.scss";
import { FC } from "react";

interface IAbout {}

export const About: FC<IAbout> = () => {
  return (
    <section className={styles.wrap}>
      <h1 className={styles.title}>
        Интернет-магазин товаров для охоты, рыбалки и туризма
      </h1>
      <p className={styles.text}>
        Наша компания — эксклюзивный представитель многих брендов, располагающий
        собственной ремонтной базой, а также сетью розничных магазинов для
        охотников и рыболовов в Москве и других городах. На всю продукцию
        действует гарантия качества.
      </p>
      <h2 className={styles.subtitle}>
        «ОхотАктив» — ваш поставщик оружия и экипировки
      </h2>
      <p className={styles.text}>
        Интернет-магазин предлагает снаряжение для охоты, рыбалки и туризма. В
        каталоге есть всё необходимое для вашей профессии или увлечения:
      </p>
      <ul className={styles.list}>
        <li className={styles.item}>
          огнестрельное оружие, включая пистолеты и охотничьи ружья
        </li>
        <li className={styles.item}>оптические приборы</li>
        <li className={styles.item}>товары для рыбалки и туризма</li>
        <li className={styles.item}>экипировку</li>
        <li className={styles.item}>
          большой выбор ножей, аксессуаров и т. д.
        </li>
      </ul>
      <Link prefetch={false} href="/about/" className={styles.link}>
        О компании
      </Link>
    </section>
  );
};
