"use client";
import styles from "./stories-popup.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow, Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/effect-coverflow";
import "./swiper-popup.scss";
import { CloseIcon, SoundIcon, SoundMutedIcon } from "@/components/icon";
import { StoriesItem } from "./stories-item/stories-item";
import { IAllStories } from "@/services/types/types";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import {
  getMutedStatus,
  setMuted,
  setUnMuted,
} from "@/services/redux/features/storiesSlice";

interface IStoriesPopup {
  focusedElem?: number;
  onClose?: () => void;
  storiesList: Array<IAllStories>;
}

export const StoriesPopup: FC<IStoriesPopup> = (props) => {
  const {focusedElem = 0, onClose = () => {}, storiesList = []} = props;
  const [focus, setFocus] = useState<number>(focusedElem);
  const isMuted = useAppSelector(getMutedStatus);
  const dispath = useAppDispatch();
  const ref = useRef<SwiperRef>(null);
  
  useEffect(() => {
    if (ref.current) {
      ref.current.swiper.slideTo(focusedElem, 0);
    }
  }, []);
  
  
  document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
      onClose();
    }
  });
  
  const nextSlide = () => {
    if (ref.current) {
      ref.current.swiper.slideNext(1000, true);
    }
  };
  const prevSlide = () => {
    if (ref.current) {
      ref.current.swiper.slidePrev(1000, true);
    }
  };
  
  const slideTo = (slideIndex: number) => {
    if (ref.current) {
      ref.current.swiper.slideTo(slideIndex, 1000, true);
    }
  };
  
  const handleMuted = () => {
    if (isMuted) {
      dispath(setUnMuted(false));
      const audioElements = document.querySelectorAll("video");
      audioElements.forEach((audio) => {
        audio.muted = false;
      });
    } else {
      const audioElements = document.querySelectorAll("video");
      audioElements.forEach((audio) => {
        audio.muted = true;
      });
      dispath(setMuted(true));
    }
  };
  return (
    <div className={styles.modal}>
      <div className={styles.wrap}>
        <div className={styles.actions}>
          <button
            className={`${styles.button} ${styles.sound}`}
            onClick={handleMuted}
          >
            {isMuted ? <SoundMutedIcon/> : <SoundIcon/>}
          </button>
          <button
            className={`${styles.button} ${styles.close}`}
            onClick={onClose}
          >
            <CloseIcon/>
          </button>
        </div>
        <div className={styles.content}>
          <Swiper
            centeredSlides={true}
            className="swiper-stories-popup"
            effect={"coverflow"}
            slidesPerView={5}
            spaceBetween={70}
            // breakpoints={breakpoints}
            speed={555}
            // autoplay={{ delay: 5300, disableOnInteraction: false }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 30,
              modifier: 4,
              slideShadows: false,
            }}
            modules={[Navigation, EffectCoverflow, Autoplay]}
            ref={ref}
            onSlideChange={() => {
              if (ref.current) {
                setFocus(ref.current.swiper.activeIndex);
              }
            }}
          >
            {storiesList.map((slideContent, index) => (
              <SwiperSlide
                key={`${index + "swiper-slide" + "storiesItem"}`}
                className={styles["swiper-slide-active"]}
              >
                <StoriesItem
                  isNext={storiesList[focus + 1] ? true : false}
                  isActive={focus === index}
                  nextSlide={nextSlide}
                  prevSlide={prevSlide}
                  handleSlide={slideTo}
                  item={slideContent}
                  onClose={onClose}
                  index={index}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
